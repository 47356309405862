/* eslint-disable max-len */
import { api } from './api';
import { IEvent } from './types';

interface IPlaylist {
  playlistId: number;
  pos: number;
}

export const platformApi = api.injectEndpoints({
  endpoints: builder => ({
    setNextPlaylistItem: builder.mutation<void, void>({
      query: () => ({
        url: '/playlist/next',
        method: 'POST',
      }),
      invalidatesTags: [],
    }),
    sendVideoPlayerPosition: builder.mutation<void, IPlaylist>({
      query: ({ playlistId, pos }) => ({
        url: `/playlist/${playlistId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: new URLSearchParams({ pos: Math.ceil(pos / 1000).toString() }),
      }),
      invalidatesTags: [],
    }),
    getPlatformEventDetails: builder.query<IEvent, void>({
      query: () => ({
        url: '/',
      }),
      providesTags: [],
    }),
  }),
});

export const {
  useGetPlatformEventDetailsQuery,
  useSendVideoPlayerPositionMutation,
  useSetNextPlaylistItemMutation,
} = platformApi;
