import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { api as platformApi } from './platformApi';
import auth from './slices/auth';
import config from './slices/config';
import room from './slices/room';
import streamingViewHistory from './slices/streamingViewHistory';
import { api as streamGoApi } from './streamGoApi';

export const store = configureStore({
  reducer: {
    [streamGoApi.reducerPath]: streamGoApi.reducer,
    [platformApi.reducerPath]: platformApi.reducer,
    config,
    auth,
    room,
    streamingViewHistory,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(streamGoApi.middleware)
      .concat(platformApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
