/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    logo: string;
  }

  interface ThemeOptions {
    logo?: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0cc2aa',
    },
    secondary: {
      main: '#5d5e6a',
    },
    background: {
      default: '#111',
      paper: '#424242',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  logo: 'https://assets.streamgo.vc/logos/streamGo-logo-white-final.png',
});

export default theme;
