/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const authenticatedBaseQuery = async (
  args: any,
  api: any,
  extraOptions: any,
) => {
  const state = api.getState() as RootState;
  const { eventId, platformBaseUrl, platformToken } = state.config;
  const baseQuery = fetchBaseQuery({
    baseUrl: `${platformBaseUrl}${eventId}`,
  });

  if (platformToken) {
    if (typeof args === 'string') {
      // If args is a string, it's a URL, so append the token
      args = `${args}${args.includes('?') ? '&' : '?'}token=${platformToken}`;
    } else if (typeof args === 'object' && args.url) {
      // If args is an object, it may have a URL property
      args.url = `${args.url}${
        args.url.includes('?') ? '&' : '?'
      }token=${platformToken}`;
    }
  }

  return baseQuery(args, api, extraOptions);
};

const baseQueryWithRetry = retry(authenticatedBaseQuery, { maxRetries: 0 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  reducerPath: 'platformApi',
  tagTypes: [],
});
