export const salt =
  '+RxwWS|$(0m?/V=^?Z1_y[oPeA*zv1AM%COnf[N`4QDL6Y<R^lX+ CtJtM=tqNv?';

async function sha256(message: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => `00${b.toString(16)}`.slice(-2)).join('');
  return hashHex;
}

export const getEventToken = async (eventId: number) => {
  const unhashed = `${eventId}${salt}`;
  return sha256(unhashed);
};
