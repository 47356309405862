/* eslint-disable max-len */
import { api } from './api';
import {
  ILiveNow,
  ILoginRequest,
  IParticipantMute,
  IPinnedMode,
  IRoom,
  IRoomRequest,
  ISetting,
  ISpeedTest,
  ITechCheckResults,
  ITokenRequest,
  IUpdateStreamingViewRequest,
  IUpdatedDynamicLayout,
  IUploadImageToS3,
  IUploadPresenterImage,
  IUser,
} from './types';

export const streamGoApi = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<string, ILoginRequest>({
      query: credentials => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'login',
          ...credentials,
        },
        responseHandler: (response: { text: () => string }) => response.text(),
      }),
      invalidatesTags: ['user'],
    }),
    check: builder.mutation<string, IRoomRequest>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'check',
          ...roomDetails,
        },
        responseHandler: (response: { text: () => string }) => response.text(),
      }),
    }),
    getAcceesTokenLiveKit: builder.mutation<string, ITokenRequest>({
      query: tokenRequest => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'accessTokenLiveKit',
          ...tokenRequest,
        },
        responseHandler: (response: { text: () => string }) => response.text(),
      }),
    }),
    updateStreamingView: builder.mutation<IRoom, IUpdateStreamingViewRequest>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'streamingview',
          ...roomDetails,
        },
      }),
    }),
    updateDynamicLayout: builder.mutation<void, IUpdatedDynamicLayout>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'dynamicLayout',
          ...roomDetails,
        },
      }),
    }),
    uploadImageToS3: builder.mutation<IRoom, IUploadImageToS3>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'uploadimages3background',
          ...roomDetails,
        },
        responseHandler: (response: { text: () => string }) => response.text(),
      }),
    }),
    uploadImage: builder.mutation<IRoom, IUploadPresenterImage>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'uploadimageLiveKit',
          ...roomDetails,
        },
        responseHandler: (response: { text: () => string }) => response.text(),
      }),
    }),
    sendTechCheckResults: builder.mutation<void, ITechCheckResults>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'techCheck',
          ...roomDetails,
        },
        responseHandler: (response: { text: () => string }) => response.text(),
      }),
    }),
    uploadPresenterImage: builder.mutation<IRoom, IUploadPresenterImage>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'uploadimageS3LiveKit',
          ...roomDetails,
        },
      }),
    }),
    updatePinned: builder.mutation<IRoom, IPinnedMode>({
      query: pinnedDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'pinnedLiveKit',
          ...pinnedDetails,
        },
      }),
    }),
    uploadSpeedTestResults: builder.mutation<IRoom, ISpeedTest>({
      query: liveNow => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'speedtest',
          ...liveNow,
        },
      }),
    }),
    updateLiveNow: builder.mutation<IRoom, ILiveNow>({
      query: liveNow => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'liveNow',
          ...liveNow,
        },
      }),
    }),
    participantMute: builder.mutation<IRoom, IParticipantMute>({
      query: participantMute => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'mute',
          ...participantMute,
        },
      }),
    }),
    getRoomDetails: builder.query<IRoom, IRoomRequest>({
      query: roomDetails => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'streaming',
          ...roomDetails,
        },
      }),
      providesTags: ['roomDetails'],
    }),
    getSettings: builder.query<Array<ISetting>, void>({
      query: () => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'settings',
        },
      }),
    }),
    getUser: builder.query<IUser, void>({
      query: () => ({
        url: '/',
        method: 'POST',
        body: {
          method: 'user',
        },
      }),
      providesTags: ['user'],
    }),
  }),
});

export const {
  useCheckMutation,
  useGetAcceesTokenLiveKitMutation,
  useGetRoomDetailsQuery,
  useGetSettingsQuery,
  useGetUserQuery,
  useLoginMutation,
  useParticipantMuteMutation,
  useSendTechCheckResultsMutation,
  useUpdateDynamicLayoutMutation,
  useUpdateLiveNowMutation,
  useUpdatePinnedMutation,
  useUpdateStreamingViewMutation,
  useUploadImageMutation,
  useUploadImageToS3Mutation,
  useUploadPresenterImageMutation,
  useUploadSpeedTestResultsMutation,
} = streamGoApi;
