/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEventToken } from '../helpers';
import { RootState } from '../store';
import { ISetting, streamGoApi } from '../streamGoApi';
import { SettingType } from '../streamGoApi/enums';

export interface IConfigState {
  baseUrl: string;
  eventId?: number;
  platformBaseUrl?: string;
  platformToken?: string;
}

const initialState: IConfigState = {
  baseUrl: '',
};

export const fetchEventToken = createAsyncThunk<string, number>(
  'config/fetchEventToken',
  async (eventId: number) => getEventToken(eventId),
);

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setBaseUrl(state, action: PayloadAction<string>) {
      state.baseUrl = action.payload;
    },
    setEventId: (state, action: PayloadAction<number>) => {
      state.eventId = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchEventToken.fulfilled, (state, action) => {
      state.platformToken = action.payload;
    });
    builder.addMatcher(
      action =>
        [streamGoApi.endpoints.getSettings.matchFulfilled].some(matcher =>
          matcher(action),
        ),
      (state, action: PayloadAction<Array<ISetting>>) => {
        const platformBaseUrl = action.payload.find(
          setting => setting.Setting === SettingType.ApiUrl,
        );

        if (platformBaseUrl) {
          state.platformBaseUrl = platformBaseUrl.Value;
        }
      },
    );
  },
});

export const { setBaseUrl, setEventId } = configSlice.actions;

export const selectEventId = (state: RootState) => state.config.eventId;
export const selectBaseUrl = (state: RootState) => state.config.baseUrl;

export default configSlice.reducer;
