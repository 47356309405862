/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const authenticatedBaseQuery = async (
  args: any,
  api: any,
  extraOptions: any,
) => {
  const state = api.getState() as RootState;
  const { token } = state.auth;
  const { baseUrl } = state.config;

  const baseQuery = fetchBaseQuery({
    baseUrl,
  });

  if (token && typeof args.body === 'object') {
    args.body = { ...args.body, token };
  } else if (token && typeof args.body === 'string') {
    try {
      const bodyObj = JSON.parse(args.body);
      args.body = JSON.stringify({ ...bodyObj, token });
    } catch (e) {
      console.error('Failed to parse request body:', e);
    }
  }

  return baseQuery(args, api, extraOptions);
};

const baseQueryWithRetry = retry(authenticatedBaseQuery, { maxRetries: 0 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  reducerPath: 'conferenceAppApi',
  tagTypes: ['roomDetails', 'user'],
});
