import { useEffect, useState } from 'react';
import '../styles/globals.scss';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { Provider } from 'react-redux';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { store } from '../redux';
import lightTheme from '../styles/lightTheme';
import darkTheme from '../styles/theme';

function MyApp({ Component, pageProps }: AppProps) {
  const [theme, setTheme] = useState(darkTheme);

  useEffect(() => {
    const route = window.location.pathname;

    if (route.startsWith('/test') || route.startsWith('/techCheck')) {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DefaultSeo
          additionalLinkTags={[
            {
              rel: 'icon',
              href: '/favicon.ico',
            },
            {
              rel: 'apple-touch-icon',
              href: '/images/livekit-apple-touch.png',
              sizes: '180x180',
            },
            {
              rel: 'mask-icon',
              href: '/images/livekit-safari-pinned-tab.svg',
              color: '#070707',
            },
          ]}
          additionalMetaTags={[
            {
              property: 'theme-color',
              content: '#red',
            },
          ]}
          defaultTitle="StreamGo | Conference App"
          description="StreamGo | Conference App"
          title="StreamGo | Conference App"
          titleTemplate="%s"
        />
        <Component {...pageProps} />
      </ThemeProvider>
    </Provider>
  );
}

export default MyApp;
