/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoom, streamGoApi } from '../streamGoApi';
import { StreamingView } from '../streamGoApi/enums';

export interface IStreamingViewHistoryState {
  streamingViewHistory: StreamingView[];
}

const initialState: IStreamingViewHistoryState = {
  streamingViewHistory: [StreamingView.WebCamsActiveSpeaker],
};

const excludedFromHistory = [StreamingView.PreRoll, StreamingView.PostRoll];

const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistory(
      state,
      action: PayloadAction<{
        streamingViewHistory: StreamingView[];
      }>,
    ) {
      const { streamingViewHistory } = action.payload;
      state.streamingViewHistory = streamingViewHistory;
    },
  },

  extraReducers: builder => {
    builder.addMatcher(
      streamGoApi.endpoints.getRoomDetails.matchFulfilled,
      (state, action: PayloadAction<IRoom>) => {
        const streamingView = action.payload.StreamingView as StreamingView;

        // do not store pre/post rolls in the history
        if (excludedFromHistory.includes(streamingView)) {
          return;
        }

        const latest =
          state.streamingViewHistory[state.streamingViewHistory.length - 1];

        if (latest !== streamingView) {
          state.streamingViewHistory.push(streamingView);
        }
      },
    );
  },
});

export const { setHistory } = slice.actions;
export default slice.reducer;
